import React, { useEffect, useCallback, useState } from 'react';
import logo from '../../assets/images/regenz-logo-color.svg';
import logoWhite from '../../assets/images/regenz-logo-white.svg';

import { useWallet } from 'use-wallet'
import { truncateFromCenter } from '../../helpers/sharedFunctions';
import { config, isWalletConnected, setWalletConnected } from '../../utils/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icons } from '../../helpers/icons';
import { regenzContract } from '../../utils/contracts';
import { greaterThan } from '../../helpers/bignumber';

interface Props {
    onClickCollection: Function,
    canViewCollection: boolean
}

function Menu(props: Props) {
    const wallet = useWallet();
    const connectedId:any = isWalletConnected();
    const [btnText, setBtnText] = useState('Connect')
    const [viewCollection, setViewCollection] = useState(false);
    const [balance, setBalance] = useState(0);

    /**
    * @dev Connect wallet to metamask
    */
    const connectToWallet = () => {
        setViewCollection(!viewCollection);
        if (wallet.status === 'disconnected') {
            wallet.connect('injected');
            setWalletConnected('injected');
        }
    }

    /**
    * @dev Checking if you connected before if yes reconnect.
    */
    const checkConnection = () => {
        if (wallet.status === 'disconnected' && connectedId != null) {
            wallet.connect(connectedId);
        }
    }

    /**
    * @dev Based on the connected chain check if you need to show the wallet address or the wrong network txt
    */
    const getConnectButtonTxt = useCallback(async () => {
        // 0x1 = Mainnet
        // 0x4 = Rinkeby
        // 0x2a = Kovan
        const id = config.networkId === 1 ? '0x1' : config.networkId === 4 ? '0x4' : '0x2a';
        if(window.ethereum) {
            window.ethereum.on('chainChanged', (changedChainID: any) => {
                if (changedChainID === id) {
                    window.location.reload();
                }
                else if (changedChainID !== id) return setBtnText('Wrong network');
                else setBtnText('Connect');
            })
    
            const chainId = await window.ethereum.request({ method: 'eth_chainId' });
            if (chainId === id && wallet && wallet.account) return setBtnText(truncateFromCenter(wallet.account));
            if (chainId !== id) setBtnText('Wrong network');
            else setBtnText('Connect');
        }
    }, [wallet])

    /**
    * @dev Getting your balance
    */
    const getNFTBalance = useCallback(async() => {
        if(wallet.account) {
            const bal = await regenzContract.methods.balanceOf(wallet.account).call();
            setBalance(bal);
        }
    }, [wallet.account])

    useEffect(() => {
        checkConnection();
    //  eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    useEffect(() => {
        getNFTBalance();
    }, [getNFTBalance])

    useEffect(() => {
        getConnectButtonTxt();
    }, [getConnectButtonTxt])

    return (
        <>
            <div id="navbar">
                <div className="container">
                    <div className="navbar-header">
                        <button type="button" className="nav-toggle nav-trigger">
                            <div className="nav-icon"><span></span></div>
                        </button>
                        <a className="logo-main" href="https://regenz.io/">
                            <span className="logo-main-dark">
                                <img src={logo} alt="Regenz" />
                            </span>
                            <span className="logo-main-light">
                                <img src={logoWhite} alt="Regenz" />
                            </span>
                        </a>
                        <div className="nav-wrap">
                            <nav id="nav-main" className="nav-full">
                                <ul>
                                    <li><a rel="noreferrer" className="n-link-1" href="https://regenz.io/#content-0" target='_blank'>AI Dreams</a></li>
                                    <li><a rel="noreferrer" className="n-link-1" href="https://regenz.io/#content-1" target='_blank'>How it Works</a></li>
                                    <li><a rel="noreferrer" className="n-link-2" href="https://regenz.io/#content-2" target='_blank'>Laboratory</a></li>
                                    <li><a rel="noreferrer" className="n-link-3" href="https://regenz.io/#content-3" target='_blank'>About</a></li>
                                    <li><a rel="noreferrer" className="n-link-4" href="https://regenz.io/#content-4" target='_blank'>NFT Value</a></li>
                                    <li><a rel="noreferrer" className="n-link-5" href="https://regenz.io/#content-5" target='_blank'>Pricing</a></li>
                                    <li><a rel="noreferrer" className="n-link-6" href="https://regenz.io/#content-6" target='_blank'>Minting</a></li>
                                    <li><a rel="noreferrer" className="n-link-7" href="https://regenz.io/#content-7" target='_blank'>FAQ</a></li>
                                </ul>
                            </nav>
                            <nav id="nav-right">
                                <ul className="nav-social">
                                    <li>
                                        <a href="https://discord.com/invite/8njaYZQgXA" target="_blank" title="Discord" rel="noreferrer">
                                            <FontAwesomeIcon icon={icons.discord} />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/Regenz_NFT" target="_blank" title="Twitter" rel="noreferrer">
                                            <FontAwesomeIcon icon={icons.twitter} />
                                        </a>
                                    </li>
                                </ul>
                                <div className='nav-connect'>
                                    {window.innerWidth > 768 &&
                                        <a rel="noreferrer" href="#" className={`button btn-main animate__animated animate__bounceIn`} onClick={() => connectToWallet()}><span>{btnText}</span></a>
                                    }
                                    <div className='nav-dropdown'>
                                        <div className='nav-dropdown-item' onClick={() => props.onClickCollection()}>Click to view your collection</div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Menu;
