import { useState } from 'react';
import placeholder from '../../assets/images/Regenz_placeholder.png';
import Rating from '@material-ui/lab/Rating';

interface Props {
    tokenid: number,
    nft: any,
    isDebug: boolean,
    onSaveImage: Function,
    callbackHTML?: React.ReactNode,
    disableSave?: boolean
}

function RegenerateCard(props: Props) {
    //console.log('regeneratecard', props.nft);
    const [hasError, setHasError]: any = useState([])

    const getRating = () => {
        const score = parseInt(props.nft && props.nft.score ? props.nft.score : 0)
        // TODO: is it swapped intentionally?
        // if (score === 5) return 1;
        // if (score === 4) return 2;
        // if (score === 3) return 3;
        // if (score === 2) return 4;
        // if (score === 1) return 5;
        // return 0;
        return score;
    }

    const onSaveRegeneratedImage = async () => {
        props.onSaveImage(props.nft.url, props.nft.score);
    }

    return (
        <div className='regen-card-wrap'>
            <div className={`alert-img ${hasError || props.isDebug ? 'placeholder' : ''}`}>
                {props.nft.url && !props.isDebug
                    ? hasError[props.nft.url]
                        // eslint-disable-next-line jsx-a11y/alt-text
                        ? <div><img src={props.nft.url} onError={e => { setHasError([props.nft.url]) }} /></div>
                        // eslint-disable-next-line jsx-a11y/alt-text
                        : <img src={props.nft.url} onError={e => { setHasError(true) }} />
                    :
                    // eslint-disable-next-line jsx-a11y/alt-text
                    <img src={placeholder} onError={e => { setHasError(true) }} />
                }
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                {props.isDebug && <img src={placeholder} />}
            </div>
            {props.nft.label && <div className='regen-name'>{props.nft.label}</div>}
            {getRating() > 0 && <div className='regen-stars'>
                <Rating name="read-only" value={getRating()} readOnly />
            </div>}
            {!props.disableSave &&
                <div className='regen-btm'>
                    <button className="button l-btn sm" onClick={() => onSaveRegeneratedImage()}><span>Save</span></button>
                </div>
            }
        </div>
    )
}

export default RegenerateCard