import Web3 from 'web3';
import { config, networkId } from './config';
import { getContractAddress } from './contracts';

const providerUrl: any = config.web3Provider;
const web3 = new Web3(providerUrl);

declare global {
    interface Window {
        ethereum:any;
    }
}

export const sendTransaction = async (provider: any, fromAddress: any, toAddress: any, encodedABI: any, wei = `0x0`, onSuccess?: Function, onError?: Function) => {
    const web3 = new Web3(window.ethereum || provider);
    if (web3) {
        try {
            const gasPrice = await web3.eth.getGasPrice();
            const tx = {
                from: fromAddress,
                to: toAddress,
                gasPrice: web3.utils.toHex(gasPrice),//`0xAB5D04C00`,
                data: encodedABI,
                value: wei
            };
            return new Promise((resolve, reject) => {
                web3.eth.sendTransaction(tx)
                    .on('receipt', (receipt) => {
                        resolve(receipt);
                        if(onSuccess) onSuccess(receipt)
                    })
                    .on('error', (err) => {
                        reject(err);
                        if(onError) onError(err)
                    });
            });
        } catch (err) {
            console.log('err :>> ', err);
            return null;
        }
    } else {
        return null;
    }
}

export const createSignature = async (provider: any, fromAddress: any, tokenid:number, onSuccess?: Function, onError?: Function) => {
    //console.log('createSignature', getContractAddress());
    const msgParams = JSON.stringify({
        domain: {
          chainId: networkId,
          name: 'Regenz',
          verifyingContract: getContractAddress(),
          version: '1',
        },
    
        // Defining the message signing data content.
        message: {
          action: "Regenerate Regenz",
          tokenid: tokenid,
          address: fromAddress
        },
        // Refers to the keys of the *types* object below.
        primaryType: 'Regenerate',
        types: {
          EIP712Domain: [
            { name: 'name', type: 'string' },
            { name: 'version', type: 'string' },
            { name: 'chainId', type: 'uint256' },
            { name: 'verifyingContract', type: 'address' },
          ],
          Regenerate: [
            { name: 'action', type: 'string' },
            { name: 'tokenid', type: 'uint256' },
            { name: 'address', type: 'address' },
          ],
        },
    });
    var params = [fromAddress, msgParams];
    var method = 'eth_signTypedData_v4';
    const web3 = new Web3(window.ethereum || provider);
    if (web3) {
        // @ts-ignore
        web3.currentProvider.sendAsync(
            {
                method,
                params,
                fromAddress
            },
            function(err:any, result:any) {
                if (err) {
                    if (onError) onError(err);
                    return;
                }
                if (onSuccess) onSuccess(result.result);
            }
        )        
    }
}

export {
    web3,
    providerUrl
}