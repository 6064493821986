import { addresses } from './addresses';
import { networkId } from './config';
import { AbiItem } from 'web3-utils'
import { web3 } from './web3';

export const getToken = (token: string) => {
  return addresses[token][networkId]
}

export const getContractOf = (ABI: any, address: string) => {
  const contract = new web3.eth.Contract(
    (ABI as unknown) as AbiItem,
    address
  )
  return contract
}
export const getContractAddress = () => {
  return getToken('REGENZ').address;
}

export const regenzContract = getContractOf(getToken('REGENZ').abi, getToken('REGENZ').address);