import { etherAPI, generatorUrl } from "../utils/config";
import { getToken } from "../utils/contracts";
import _ from 'lodash';

export const getAccountTransactionsBasedOnContract = async (addr: string) => {
    try {
        const baseUrl = `${etherAPI}/api?module=account&action=tokennfttx&contractaddress=${getToken('REGENZ').address}&address=${addr}&page=1&offset=1000&sort=desc&apikey=${process.env.REACT_APP_API_KEY}`;
        const res = await fetch(baseUrl, { method: "GET" });
        const json = await res.json();
        const duplicateTokenIDS: Array<any> = [];

        // Finding the duplicate token ID's and pushing them into the duplicate array
        let duplicatesArr = _.difference(json.result, _.uniqBy(json.result, 'tokenID'), 'json.result');
        duplicatesArr.forEach((element: any) => {
            duplicateTokenIDS.push(element.tokenID);
        });

        // Removing that one
        let newResult: Array<any> = [];
        json.result.forEach((res: any) => {
            duplicateTokenIDS.forEach((duplicateID: any) => {
                if(res.tokenID !== duplicateID) {
                    newResult.push(res);
                }
            });    
        });
        
        if(newResult.length > 0) {
            json.result = newResult
        }
        return json;
    } catch {
        return {}
    }
}

export const getTokensByAccount = async (address:string) => {
    try {
        // create a call to etherscan to get a list of all NFT transactions for the wallet address
        const baseUrl = process.env.REACT_APP_MORALIS_API + '/' + address + '/nft/' + getToken('REGENZ').address + '?chain=0x' + process.env.REACT_APP_CHAIN_ID + '&format=decimal';
        const res = await fetch(baseUrl, { method: "GET",
            headers: {
                'Accept': 'application/json',
                'X-API-Key': process.env.REACT_APP_MORALIS_API_KEY!
            }
        });
        const json = await res.json();
        //console.log('json', json);
        return json.result;
    } catch {
        // return an empty list
        return [];
    }
}


/**
 * 
 * @returns a list of models
 * model: {name:string, value:string}
 */
export const getRegenerateModels = async () => {
	const baseUrl = generatorUrl + 'models';
	const res = await fetch(baseUrl, {method: "GET"});
	const json = await res.json();
	return json.result == "success" ? json.models : []
}

/**
 * args: 
 * 		@required img: input image url as string
 * 		@required model: string, retrieved from getRegenerateModels; example "apes_cyclegan"
 * 		@optional upscale: boolean, if true will upscale all the generated images
 * @returns a list with objects
 * example object:  {
      "image_name": "0Cran_0_01_01_real_A.png", 
      "label": "real_A", 
      "rel_path": "{full url to image}", 
      "score": 3.9815680496394634, 
      "upscaled_img": "{full url to upscaled image}"
    }
 */
export const regenerateNftImage = async (img:string, model:string, upscale:boolean = false) => {
	const baseUrl = generatorUrl + 'generate';
	const fullUrl = baseUrl + '?image=' + img + '&modelname=' + model + '&upscale=' + upscale
    try {
        let res = await fetch(fullUrl, {method: "GET"});
		let json = await res.json();

		if(json.result == "busy") {
			return "busy"
		}
        return json.result == "success" ? json.data : []
    } catch(err) {
        console.log('Error!!!');
        return false;
    }
	
}

export const upscaleImg = async (img:string) => {
	const baseUrl = generatorUrl + 'upscale_image';
	const fullUrl = baseUrl + '?image=' + img;
    try {
        const res = await fetch(fullUrl, {method: "GET"});
        const json = await res.json();
		if(json.result == "busy") {
			return "busy";
		} 
        return json.result == "success" ? json : ""
    } catch {
        return false;
    }
}

export const doRegenerateNFTCall = async (account:any, nftid: number, tokenid:number, model:string, image:string, score:number, signature:string) => {
    //const baseURL: string = "https://api.cacticlub.io/token/edit";
    const baseURL: string = process.env.REACT_APP_REGENERATOR_API || 'https://museum.regenz.io/api/token/edit';
        
    const res = await fetch(baseURL, {
        method: 'POST', headers: { "Content-Type": "application/json" }, body: JSON.stringify({
          nftid: nftid,
          tokenid: tokenid,
          address: account,
          model: model,
          score: score,
          image: image,
          signature
        })
      })
      const txtRes = await res.json();
      return txtRes;
    //   if (txtRes === 'Done writing json') {
    //     //feedbackText = { ...feedbackText, [index]: txtRes };
    //     //setRenameHasError(false);
    //   } else {
    //     //feedbackText = { ...feedbackText, [index]: txtRes };
    //     //setRenameHasError(true);
    //   }
  }
