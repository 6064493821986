import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { numberWithCommas } from '../../helpers/sharedFunctions';

interface Props {
    open: boolean,
    onClose: Function,
    maxBuy: number,
    maxTotalSupply: number,
    price: number    
}

function TextDialog(props: Props) {

    return (
        <Dialog
            className='text-dialog'
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Detailed information</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <p><strong>Current Mint:</strong> Regenz (Series 1)</p>
                    <p><strong>Description:</strong> The first edition of AI-generated mashups of the most iconic NFT collections.</p>
                    <p><strong>Remixed Collections:</strong> Wicked Craniums, Cool Cats, Bored Ape Yacht Club, and Crypto Punks</p>
                    <p><strong>Mint Cap:</strong> {numberWithCommas(props.maxTotalSupply)}</p>
                    <p><strong>Listing Price:</strong> {props.price} ETH per Regen</p> 
                    <p><strong>Max Buy:</strong> {props.maxBuy} Regenz per transaction</p>
                    <p><strong>Instructions:</strong> Type a custom number OR select a set number of Regenz to mint (up to the max of {props.maxBuy}) and click Mint NFT. Complete the transaction in your wallet.</p>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button className="button btn-main" onClick={() => props.onClose()}>
                    <span>Close</span>
                </button>
            </DialogActions>
        </Dialog>
    );
}

export default TextDialog;
