import React from 'react'
import ReactDOM from 'react-dom'
import Home from './containers/Home/Home'
import Routing from './router/Router'
import reportWebVitals from './reportWebVitals'
import './theme/global.scss'
import History from './helpers/History'

import { Router } from 'react-router-dom'
import { UseWalletProvider } from 'use-wallet'
import { config } from './utils/config'

ReactDOM.render(
  <UseWalletProvider chainId={config.networkId}>
    <Router history={History}>
      <Routing />
    </Router>
  </UseWalletProvider>,
  document.getElementById('outer-wrapper'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
