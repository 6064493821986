import React, { useState } from 'react';
import placeholder from '../../assets/images/Regenz_placeholder.png';
import Rating from '@material-ui/lab/Rating';
import { getRegenerateModels, regenerateNftImage, upscaleImg } from '../../helpers/calls';

interface Props {
    nft: any,
    isDebug: boolean
    onRegenerate: Function,
}

function RegenCard(props: Props) {
    //console.log(props.nft);
    const [hasError, setHasError]: any = useState([])

   const getRating = () => {
        const score = parseInt(props.nft && props.nft.metadata && props.nft.metadata.attributes && props.nft.metadata.attributes[1] && props.nft.metadata.attributes[1].value ? props.nft.metadata.attributes[1].value : 0)
        // if(score === 5) return 1;
        // if(score === 4) return 2;
        // if(score === 3) return 3;
        // if(score === 2) return 4;
        // if(score === 1) return 5;
		//return 0;
        return score;
    }

	const startRegenerateImage = async () => {
		props.onRegenerate(props.nft);
        
        //const possibleModels:any[] = await getRegenerateModels();

        // close popup and open regenerate popup



		//regenerateNftImage(props.nft.image, possibleModels[0].value)
		// upscaleImg(props.nft.image)
	}

    return (
     <div className='regen-card-wrap'>
            <div className={`alert-img ${hasError || props.isDebug ? 'placeholder' : ''}`}>
                {props.nft.metadata && props.nft.metadata.image && !props.isDebug
                    ? hasError[props.nft.metadata.image]
                        // eslint-disable-next-line jsx-a11y/alt-text
                        ? <div><img src={props.nft.metadata.image} onError={e => { setHasError([props.nft.image]) }} /></div>
                        // eslint-disable-next-line jsx-a11y/alt-text
                        : <img src={props.nft.metadata.image} onError={e => { setHasError(true) }} />
                    :
                    // eslint-disable-next-line jsx-a11y/alt-text
                    <img src={placeholder} onError={e => { setHasError(true) }} />
                }
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                {props.isDebug && <img src={placeholder} />}
            </div>
				{ props.nft.metadata && props.nft.metadata.name && <div className='regen-name'>{props.nft.metadata.name}</div>}
				{getRating() > 0 && <div className='regen-stars'>
					<Rating name="read-only" value={getRating()} readOnly />
				</div>}
				<div className='regen-btm'>
					{ props.nft.token_id && 
						<button className="button l-btn sm" onClick={() => window.open(`https://opensea.io/assets/0x15d98e8762b2183d8ad313de3cb247c9064dfd5d/${props.nft.token_id}`, '_blank')}><span>OpenSea</span></button>
					}	
					{ props.nft.token_id && props.nft.metadata.image && 
						<button className="button l-btn sm" onClick={() => startRegenerateImage() }><span>Regenerate Image</span></button>
					}	
				</div>
			</div>
    )
}

export default RegenCard