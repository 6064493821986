import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icons } from '../../helpers/icons';

interface Props {
	open: boolean
	title: string,
	subtitle: string,
	isSuccess: boolean,
	isError: boolean
}

function LoaderDialog(props: Props) {

	useEffect(() => { }, [props.isSuccess, props.isError])

	return (
		<Dialog
			open={props.open}
			onClose={(event, reason) => {
				if (reason !== 'backdropClick') {
					return;
				}
			}}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			className='loader-dialog'>
			<DialogContent>
				<div className="alert-dialog-loading">
					<div className="alert-dialog-loading-icon">
						{(!props.isSuccess && !props.isError) && <CircularProgress color="secondary" />}
						{props.isSuccess &&
							<div className='dialog-icon-success'>
								<FontAwesomeIcon icon={icons.checkmark} />
							</div>
						}
						{props.isError &&
							<div className='dialog-icon-error'>
								<FontAwesomeIcon icon={icons.error} />
							</div>
						}
					</div>
					<div className="alert-dialog-loading-title">
						{props.title}<span>{(!props.isSuccess || !props.isError) ? '...' : ''}</span>
					</div>
					<div>
						{props.subtitle}
					</div>
				</div>
			</DialogContent>
		</Dialog>
	)
}

export default LoaderDialog