import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icons } from '../../helpers/icons'
import { getOpenSeaLink } from '../../utils/config'
import { getToken } from '../../utils/contracts'
// Import Swiper styles
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/scrollbar/scrollbar.scss'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import PuffLoader from 'react-spinners/PuffLoader'
import RegenCard from '../RegenCard/RegenCard'
import placeholder from '../../assets/images/Regenz_placeholder.png';

interface Props {
  open: boolean
  isDebug: boolean
  nfts: Array<any>
  isViewingCollection: boolean
  canViewCollection: boolean
  isLoading: boolean
  onClose: Function
  onRegenerate: Function
}

function RegenDialog(props: Props) {
  const [activeIndex, setActiveIndex] = useState(1)
  const [swiper, setSwiper]: any = useState<Swiper>()

  useEffect(() => {}, [props.open, props.isLoading])

  return (
    <Dialog
      id="showcase-dialog"
      open={props.open || props.isDebug}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {props.isViewingCollection
          ? 'Your collection'
          : props.nfts.length <= 1
          ? `Congratulations, you minted ${props.nfts.length} Regenz!`
          : `Congratulations, you minted ${props.nfts.length} Regenz!`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {!props.isLoading && props.nfts.length > 0
            ? 'The results are unique, sometimes alluring, and often even disturbing, but are undoubtedly 100% fascinating.'
            : !props.isLoading && props.nfts.length <= 0
            ? "You aren't connected or haven't added any Regenz to your collection yet!"
            : 'Collecting your Regenz...'}
        </DialogContentText>
        <div className={`slider-wrap ${props.isLoading ? 'is-loading' : ''}`}>
          {props.nfts.length > 1 && (
            <div className="previous" onClick={() => swiper.slidePrev()}>
              <FontAwesomeIcon icon={icons.chevronLeft} />
            </div>
          )}
          {props.isLoading ? (
            <PuffLoader />
          ) : (
            <>
              {props.nfts.length > 0 && (
                <Swiper
                  onSlideChange={(e) => setActiveIndex(e.realIndex + 1)}
                  onSwiper={(swiper: any) => setSwiper(swiper)}
                  slidesPerView={1}
                >
                  {!props.isDebug &&
                    props.nfts.length > 0 &&
                    props.nfts.map((nft: any) => {
                      return (
                        <React.Fragment key={nft.metadata.external_url}>
                          <SwiperSlide key={nft.metadata.external_url}>
                            <RegenCard isDebug={props.isDebug} nft={nft} onRegenerate={props.onRegenerate} />
                          </SwiperSlide>
                        </React.Fragment>
                      )
                    })}
                </Swiper>
              )}
              {props.nfts.length <= 0 && (
                <Swiper
                  onSlideChange={(e) => setActiveIndex(e.realIndex + 1)}
                  onSwiper={(swiper: any) => setSwiper(swiper)}
                  slidesPerView={1}
                >
                  <SwiperSlide>
                    <RegenCard isDebug={props.isDebug} nft={{ image: placeholder }} onRegenerate={props.onRegenerate} />
                  </SwiperSlide>
                </Swiper>
              )}
            </>
          )}
          {props.nfts.length > 1 && (
            <div className="next" onClick={() => swiper.slideNext()}>
              <FontAwesomeIcon icon={icons.chevronRight} />
            </div>
          )}
        </div>
        {!props.isLoading && (
          <span className="alert-img-amount">
            {activeIndex}/{props.nfts.length === 0 ? 1 : props.nfts.length}
          </span>
        )}
      </DialogContent>
      <DialogActions>
        <button
          className="button l-btn"
          onClick={() =>window.open('https://opensea.io/account', '_blank')}>
          <span>Visit Opensea</span>
        </button>
        {props.isViewingCollection ? (
          <button
            className="button btn-main"
            onClick={() => {
              props.onClose()
              setActiveIndex(1)
            }}
          >
            <span>Close</span>
          </button>
        ) : (
          <button
            className="button btn-main"
            onClick={() => window.location.reload()}
          >
            <span>Mint more</span>
          </button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default RegenDialog
