import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icons } from '../../helpers/icons'
// Import Swiper styles
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/scrollbar/scrollbar.scss'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import RegenerateCard from '../RegenerateCard/RegenerateCard'
import { CircularProgress } from '@material-ui/core'
import { doRegenerateNFTCall, getRegenerateModels, regenerateNftImage, upscaleImg } from '../../helpers/calls'
import { useWallet } from 'use-wallet'
import { createSignature } from '../../utils/web3'
import { mockRegenerations } from '../../assets/mock/mockdata';
import { forEach } from 'lodash'
import { resolve } from 'dns'

interface Props {
  open: boolean
  isDebug: boolean
  nfts: Array<any>
  nft: any
  isViewingCollection: boolean
  canViewCollection: boolean
  isLoading: boolean
  onClose: Function
}

function RegenerateRegenz(props: Props) {
  const [activeIndex, setActiveIndex] = useState(1)
  const [swiper, setSwiper]: any = useState<Swiper>()
  const [fetchingRegenerates, setFetchinRegenerates] = useState(false);
  const [chooseModelState, setChooseModelState] = useState(true);
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedNFT, setSelectedNFT]: any = useState({});
  const [regenerateNFTS, setRegenerateNFTS]: any = useState([])
  const [regenerateModels, setRegenerateModels]: any = useState([])
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [success, setSuccess] = useState(false);
  const [signature, setSignature] = useState("");
  const { account, ethereum } = useWallet()
  const [hasLoadingError, setHasLoadingError] = useState(false);

  useEffect(() => {
    //console.log('USE EFFECT', "loading", loading, "fetchingRegenerates", fetchingRegenerates);
    if (chooseModelState && regenerateModels.length === 0) {
      fetchRegenerateModels();
    }

  }, [props.open, loading])

  const fetchRegenerateModels = async () => {
    const possibleModels: any[] = await getRegenerateModels();
    //console.log('possibleModels', possibleModels, possibleModels.length);
    setRegenerateModels(possibleModels);
  }

  const fetchRegenerates = async (model: string) => {
    //console.log('REGENERATE NFT: ', props.nft);
    setSelectedNFT(props.nft);
    setSelectedModel(model);
    setLoadingText("Loading regenerated images. This may take a while..");
    setLoading(true);
    setFetchinRegenerates(true);
    // get the images for the selected model
    regenerateCall(model);
    //console.log('images', images);   
  }

  const regenerateCall: any = (model: string) => {
    regenerateNftImage(props.nft.metadata.image, model).then((res) => {
      if (res == "busy") {
        setTimeout(() => {
          regenerateCall(model)
        }, 1000);
      } else {
        onImagesRegenerated(res);
      }
    });
  }

  const onImagesRegenerated = (images: any) => {
    //console.log('onImagesRegenerated', images);
    // hotfix for urls
    if (images) {
      if (images.data) {
        images.data.forEach((image: any) => {
          image.url = image.url.replace('http://', 'https://');
        })
        // mock data:
        //const images = mockRegenerations();
        setRegenerateNFTS(images.data);
        //console.log(images.data.length)
        if (images.data.length === 0) {
          setChooseModelState(true);
          setLoadingText("Error regenerating images. Try again later...");
          setHasLoadingError(true)
        } else {
          // set fetchiing state to false
          setFetchinRegenerates(false);
        }
      }

    } else {
      // regeneration failed, so go to previous options	
      setChooseModelState(true);
      setLoadingText("Error regenerating images. Try again later...");
      setHasLoadingError(true)
    }
    setLoading(false);
  }

  const upscaleSelectedImage = async (image: string, score: number, signature: string) => {
    const upscaledImage = await upscaleImg(image);
    if (upscaledImage == "busy") {
      setTimeout(() => {
        upscaleSelectedImage(image, score, signature);
      }, 1000);
    } else {
      onUpscaledImage(upscaledImage, score, signature);
    }

  }

  const onUpscaledImage = async (upscaledImage: any, score: number, signature: string) => {
    setLoadingText("Uploading image...");
    if (upscaledImage) {
      // do call to backend
      try {
        const res = await doRegenerateNFTCall(account, selectedNFT.token_id, selectedNFT.metadata.ID, selectedModel, upscaledImage.url, score, signature);
        if (res.status === 'success') {
          //console.log('Status: SUCCESS!');
          setSuccess(true);

        } else {
          console.log('Error fetching regenerate nfts');
          setHasLoadingError(true)
          setLoadingText("Unable to save the new image, Try again later...");
        }

      } catch (e) {
        setLoadingText("Unable to save the new image, Try again later...");
        setHasLoadingError(true)
      }
    } else {
      // something went wrong
      setLoadingText("Unable to save the new image, Try again later...");
      setHasLoadingError(true)
    }
    setLoading(false);
  }

  const onSaveRegeneratedImage = async (image: string, score: number) => {
    //console.log('onSaveRegenerateImage');
    // check if there is a signature already
    if (signature) {
      setLoadingText("Preparing image to be uploaded...");
      setLoading(true);
      upscaleSelectedImage(image, score, signature);
    } else {
      try {
        setLoadingText("Preparing image to be uploaded...");
        setLoading(true);
        console.log('selectedNFT', selectedNFT);
        await createSignature(ethereum, account, selectedNFT.metadata.ID, async (signature: any) => {
          setSignature(signature);
          // signature created, do the renaming call
          upscaleSelectedImage(image, score, signature);
        },
          (error: any) => {
            setLoadingText("Unable to create signature, Try again later...");
            setHasLoadingError(true)
            console.log('Error creating signature')
            setLoading(false);
          },
        )
      } catch {
        setLoadingText("Unable to create signature, Try again later...");
        setHasLoadingError(true)
        console.log('Catch creating signature')
        setLoading(false);
      }
    }
  }

  const getDescriptionText = () => {
    if (hasLoadingError) return loadingText;
    if (chooseModelState) {
      return "Choose a model for your new Regenz";
    }
    if (success) {
      return "Your Regenz has been successfully regenerated, it can take a moment before the new image shows up in your collection";
    }
    if (!loading && regenerateNFTS.length > 0) {
      return "Choose a regenated image";
    }
    if (!loading && regenerateNFTS.length <= 0) {
      return "You haven't added any Regenz to your collection yet!";
    }
  }

  const renderErrorDialog = () => {
    return (
      <Dialog
        open={props.open || props.isDebug}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            return;
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='loader-dialog error-dialog'>
        <DialogContent>
          <div className="alert-dialog-loading">
            <div className="alert-dialog-loading-icon">
              <div className='dialog-icon-error'>
                <FontAwesomeIcon icon={icons.error} />
              </div>
            </div>
            <div className="alert-dialog-loading-title">
              {loading ? loadingText : getDescriptionText()}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            className="button btn-main"
            onClick={() => {
              props.onClose()
              window.location.reload();
              // reset all values
              setTimeout(() => {
                setChooseModelState(true);
                setRegenerateNFTS([])
                setRegenerateModels([])
                setLoading(false);
                setHasLoadingError(false)
              }, 200);
            }}
          >
            <span>Close</span>
          </button>
        </DialogActions>
      </Dialog>)
  }

  return hasLoadingError ? renderErrorDialog() : (
    <Dialog
      id="showcase-dialog"
      open={props.open || props.isDebug}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Regenerate your Regenz</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {loading ? loadingText : getDescriptionText()}
        </DialogContentText>
        <div className={`slider-wrap ${loading ? 'is-loading' : ''}`}>
          {regenerateNFTS.length > 1 && !loading && !success && (
            <div className="previous" onClick={() => swiper.slidePrev()}>
              <FontAwesomeIcon icon={icons.chevronLeft} />
            </div>
          )}
          {loading ? (
            <CircularProgress color="secondary" />
          ) : !success && (
            <>
              {regenerateNFTS.length > 0 && (
                <Swiper
                  onSlideChange={(e) => setActiveIndex(e.realIndex + 1)}
                  onSwiper={(swiper: any) => setSwiper(swiper)}
                  slidesPerView={1}
                >
                  {!props.isDebug &&
                    regenerateNFTS.length > 0 &&
                    regenerateNFTS.map((nft: any) => {
                      return (
                        <React.Fragment key={props.nft.url}>
                          <SwiperSlide key={props.nft.url}>
                            <RegenerateCard isDebug={props.isDebug} nft={nft} tokenid={props.nft.token_id} onSaveImage={onSaveRegeneratedImage} />
                          </SwiperSlide>
                        </React.Fragment>
                      )
                    })}
                  {(props.isDebug || !props.canViewCollection) && (
                    <SwiperSlide>
                      <RegenerateCard isDebug={props.isDebug} nft={{}} tokenid={0} onSaveImage={() => { }} />
                    </SwiperSlide>
                  )}
                </Swiper>
              )}
              {regenerateNFTS.length <= 0 && (
                <Swiper
                  onSlideChange={(e) => setActiveIndex(e.realIndex + 1)}
                  onSwiper={(swiper: any) => setSwiper(swiper)}
                  slidesPerView={1}
                >
                  <SwiperSlide>
                    <RegenerateCard disableSave isDebug={props.isDebug} nft={{}} tokenid={0} onSaveImage={() => { }} />
                  </SwiperSlide>
                  {(props.isDebug || !props.canViewCollection) && (
                    <SwiperSlide>
                      <RegenerateCard
                        disableSave
                        isDebug={props.isDebug}
                        nft={{}}
                        tokenid={0}
                        onSaveImage={() => { }}
                      />
                    </SwiperSlide>
                  )}
                </Swiper>
              )}
            </>
          )}
          {regenerateNFTS.length > 1 && !loading && !success && (
            <div className="next" onClick={() => swiper.slideNext()}>
              <FontAwesomeIcon icon={icons.chevronRight} />
            </div>
          )}
        </div>
        {!loading && regenerateNFTS.length > 0 && !success && (
          <span className="alert-img-amount">
            {props.isDebug || !props.canViewCollection || regenerateNFTS.length <= 0
              ? 0
              : activeIndex}
            /{regenerateNFTS.length}
          </span>
        )}
      </DialogContent>
      <DialogActions>
        {chooseModelState && !loading && (
          <div className="dropdown-wrapper">
            <div className="dropdown dropup">
              <button className="button l-btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                Choose model
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {
                  regenerateModels.map((model: any, index: number) => {
                    return <button key={model.value + '_' + index} className="dropdown-item" onClick={() => {
                      fetchRegenerates(model.value);
                      setChooseModelState(false);
                    }}
                    >
                      {model.name}
                    </button>
                  })
                }
              </div>
            </div>
          </div>
        )}
        <button
          className="button btn-main"
          onClick={() => {
            props.onClose()
            // reset all values
            setTimeout(() => {
              setChooseModelState(true);
              setRegenerateNFTS([]);
              setRegenerateModels([])
              setSuccess(false);
              setSignature("");
              setLoading(false);
            }, 200);

          }}
        >
          <span>Close</span>
        </button>
      </DialogActions>
    </Dialog>
  )
}

export default RegenerateRegenz
