import BigNumber from 'bignumber.js';

export const equalTo = (numberOne: any, numberTwo: any) => {
    return (
        new BigNumber(numberOne.toString()).comparedTo(
            new BigNumber(numberTwo.toString())
        ) === 0
    );
}

export const greaterThan = (numberOne: any, numberTwo: any) => {
    return (
        new BigNumber(numberOne.toString()).comparedTo(
            new BigNumber(numberTwo.toString())
        ) === 1
    );
}

export const greaterThanOrEqual = (numberOne: any, numberTwo: any) => {
    return (
        new BigNumber(numberOne.toString()).comparedTo(
            new BigNumber(numberTwo.toString())
        ) >= 0
    );
}

export const subtract = (numberOne: any, numberTwo: any) => {
    return new BigNumber(numberOne.toString())
        .minus(new BigNumber(numberTwo.toString()))
        .toFixed();
}

export const add = (numberOne: any, numberTwo: any) => {
    return new BigNumber(numberOne.toString())
        .plus(new BigNumber(numberTwo.toString()))
        .toFixed();
}

export const multiply = (numberOne: any, numberTwo: any) => {
    return new BigNumber(numberOne.toString())
        .times(new BigNumber(numberTwo.toString()))
        .toFixed();
}

export const divide = (numberOne: any, numberTwo: any, fixedNumber?: number) => {
    if(fixedNumber) {
        return new BigNumber(numberOne.toString())
        .dividedBy(new BigNumber(numberTwo.toString()))
        .toFixed(fixedNumber);
    }
    return new BigNumber(numberOne.toString())
        .dividedBy(new BigNumber(numberTwo.toString()))
}

export const minimum = (numberOne: any, numberTwo: any) => {
    return BigNumber.min(numberOne.toString(), numberTwo.toString()).toFixed();
}

export const maximum = (numberOne: any, numberTwo: any) => {
    return BigNumber.max(numberOne.toString(), numberTwo.toString()).toFixed();
}

export const BN = (number: any) => {
    return new BigNumber(number);
}
export const BnTimes = (number: any) => {
    return new BigNumber(number).times(1e18).toString();
}

