import REGENZ_ABI from '../assets/abi/regenz.json'

export const addresses: any = {
    'REGENZ': {
        1: {
            'address': '0x15d98E8762B2183D8Ad313DE3cb247C9064Dfd5D',
            'abi': REGENZ_ABI
        },
        4: {
            'address': '0xB0748406f25444aBd388575cBC5c7dc36A6c9136',
            'abi': REGENZ_ABI
        },
        42: {
            'address': '0x0db7676f6aA782296F36761b251B4a8030a9DEA1',
            'abi': REGENZ_ABI
        },
    }
}