import React, { useEffect, useState, useCallback, useMemo } from 'react'
import Menu from '../../components/Menu/Menu'
import bannerImage from '../../assets/images/regenz-dap-banner.jpg'
import RegenDialog from '../../components/RegenDialog/RegenDialog'
import RegenRegenerate from '../../components/RegenRegenerate/RegenRegenerate';
// import baseJSON from '../../assets/json/json_file.json';
import TextDialog from '../../components/TextDialog/TextDialog'
import LoaderDialog from '../../components/Loader/Loader'
import moment from 'moment'

import { getToken, regenzContract } from '../../utils/contracts'
import {
  BN,
  divide,
  equalTo,
  greaterThan,
  greaterThanOrEqual,
  multiply,
} from '../../helpers/bignumber'
import { useWallet } from 'use-wallet'
import { sendTransaction, web3 } from '../../utils/web3'
import { getAccountTransactionsBasedOnContract, getTokensByAccount } from '../../helpers/calls'

function Home() {
  const [NFTShowcaseDebug] = useState(false)

  const { ethereum }: { ethereum: any } = useWallet()
  const { account } = useWallet()
  const [value, setValue] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [totalSupply, setTotalSupply] = useState(0)
  const [maxTotalSupply, setMaxTotalSupply] = useState(0)
  const [pricePerToken, setPricePerToken] = useState(BN(0).toString())
  const [maxTokensPerTransaction, setMaxTokensPerTransaction] = useState(
    BN(10).toString(),
  )
  const [allTokensAreBought, setAllTokensAreBought] = useState(false)
  const [regenDialogState, setRegenDialogState] = useState(false)
  const [regenRegenerateState, setRegenRegenerateState] = useState(false)
  const [textDialogState, setTextDialogState] = useState(false)
  const [loaderDialogState, setLoaderDialogState] = useState(false)
  const [loadingText, setLoadingText] = useState(['', ''])
  const [boughtNFTS, setBoughtNFTS]: any = useState([])
  const [showcaseNFTSIsLoading, setShowcaseNFTSIsLoading] = useState(false)
  const [showcaseNFTS, setShowcaseNFTS]: any = useState([])
  const [isViewingCollection, setIsViewingCollection] = useState(false)
  const [isMinting, setIsMinting] = useState(false)
  const [canViewCollection, setCanViewCollection] = useState(false)
  const [mintBtnDisabled, setMintBtnDisabled] = useState(true)
  const buyAmount = useMemo(
    () => multiply(multiply(value, pricePerToken), 1e18),
    [pricePerToken, value],
  )

  const [regenNFT, setRegenNFT] = useState({});

  /**
   * @dev Get the current price for 1 NFT
   */
  const getPricePerNFT = async () => {
    const priceInWei = await regenzContract.methods.PRICE().call()
    const priceInEth = divide(priceInWei, 1e18)
    setPricePerToken(BN(priceInEth).toString())
  }

  /**
   * @dev Get the MAX tokens per transaction you can MINT
   */
  const getMaxTokensPerTX = async () => {
    const max = await regenzContract.methods.MAX_PER_TX().call()
    setMaxTokensPerTransaction(max)
  }

  /**
   * @dev Makes a transaction to mint a NFT
   *
   * After the transaction the total supply will get updated
   */
  const buyNFT = async () => {
    setIsLoading(true)
    setLoaderDialogState(true)
    setIsMinting(true)
    setLoadingText([
      'Creating transaction',
      'Track your wallet for further details and progression.',
    ])
    const amountOfTokens = BN(value).toNumber()
    const func = await regenzContract.methods
      .mintRegenz(amountOfTokens)
      .encodeABI()
    await sendTransaction(
      ethereum,
      account,
      getToken('REGENZ').address,
      func,
      buyAmount,
      async (tx: any) => {
        setLoadingText([
          'Retrieving Regenz',
          'We are almost there. Any moment now...',
        ])
        let arr: any = []
        if (account) {
          for (let i = 0; i < tx.logs.length; i++) {
            const indexInHex = tx.logs[i].topics[3]
            const number = web3.utils.hexToNumber(indexInHex)
            const baseURL = await regenzContract.methods.tokenURI(number).call()
            const res = await fetch(baseURL, { method: 'GET' })
            const json = await res.json()
            json.tokenID = number
            arr.push(json)
            if (arr.length === BN(value).toNumber()) {
              setIsLoading(false)
              setLoaderDialogState(false)
              setShowcaseNFTS(arr)
              setRegenDialogState(true)
              setCanViewCollection(false)
            }
          }
        }
      },
      (data: any) => {
        if (data.code === 4001) {
          setLoadingText(['Transaction signature denied', 'Try again...'])
        } else {
          setLoadingText(['Transaction failed', 'Try again...'])
        }
        setTimeout(() => {
          setIsLoading(false)
          setLoaderDialogState(false)
        }, 2000)
      },
    )
  }

  /**
   * @dev Get the current minted supply
   */
  const getTotalSupply = async () => {
    const totalSupply = await regenzContract.methods.totalSupply().call()
    setTotalSupply(BN(totalSupply).toNumber())
  }

  /**
   * @dev Get the max supply that can be minted
   */
  const getMaxTotalSupply = async () => {
    const MAX_TOTAL_SUPPLY = await regenzContract.methods.TOTAL_SUPPLY().call()
    setMaxTotalSupply(BN(MAX_TOTAL_SUPPLY).toNumber())
  }

  /**
   * @dev Disable the total supply based on the max total supply and the current total supply
   */
  const disableBuyOnTotalSupply = useCallback(() => {
    if (greaterThanOrEqual(totalSupply, maxTotalSupply))
      setAllTokensAreBought(true)
    else setAllTokensAreBought(false)
  }, [totalSupply, maxTotalSupply])

  /**
   * @dev Check if the current value is a float
   * @param N Could be a string | number | float
   */
  const isFloat = (n: any) => {
    return (
      !isNaN(n) &&
      (function (x) {
        return (x | 0) === x
      })(parseFloat(n))
    )
  }

  /**
   * @dev Get your bought NFT's based on etherscan api transactions
   */
  const getBoughtNFTS = useCallback(async () => {
    if (account !== null && !isMinting) {
      setCanViewCollection(false)
      setShowcaseNFTSIsLoading(true)
      
      const nfts = await getTokensByAccount(account);
      // json encode metadata
      for (let nft of nfts) {
          // Get Bought Token ID's
          const res = await fetch(nft.token_uri, { method: 'GET' })
          const json = await res.json()
          if (json) {
            //nft.metadata = JSON.parse(nft.metadata);
            nft.metadata = json;
          }
      }
      //console.log('nfts', nfts);
      if (nfts) {
        setBoughtNFTS(nfts)
        setCanViewCollection(true)
        setShowcaseNFTSIsLoading(false)
      }
    }
  }, [account, isMinting])

  /**
   * @dev Enables the button for people to mint
   * @note Move this maybe to a component because now the whole page get's rerendered every second because of the interval.
   */
  const enableMintButton = () => {
    setInterval(() => {
      const dateString = '2021-08-14 17:00:00'
      const endDateInUNIX = moment.utc(dateString).utc().unix()
      const nowTimeInUNIX = moment().unix()
      if (nowTimeInUNIX >= endDateInUNIX) {
        setMintBtnDisabled(false)
      }
    }, 1000)
  }

  // /**
  // * @important DON'T ENABLE THIS IN PRODUCTION BUILD
  // * This version was made for regenz special because there was no opensea metadata generator yet.
  // */
  // const createOpenseaJSON = () => {
  //   const entries = Object.entries(baseJSON);
  //   let results = [];
  //   for (let i = 0; i < entries.length; i++) {
  //     const r_id = entries[i][1].ID;
  //     const r_name = entries[i][1].Name;
  //     const r_description = entries[i][1].Description;
  //     const r_filename = entries[i][1].Filename;
  //     const r_class = entries[i][1].Class;
  //     const r_series = entries[i][1].Series;
  //     const r_score = entries[i][1].Current_Score;
  //     const r_image = "https://museum.regenz.io/gallery/" + r_filename;
  //     const r_nr00 = entries[i][1].NR_00;
  //     const r_nr01 = entries[i][1].NR_01;

  //     const obj = {
  //         "ID": r_id,
  //         "Description": r_description,
  //         "Class": r_class,
  //         "Name": r_name,
  //         "Score": r_score,
  //         "Image": r_image,
  //         "Series": r_series,
  //         "GR": r_nr00,
  //         "LR": r_nr01
  //     }
  //     results.push(obj);
  //   }
  //   console.log(results)
  // }

  useEffect(() => {
    getTotalSupply()
    getMaxTotalSupply()
    getPricePerNFT()
    getMaxTokensPerTX()
    // createOpenseaJSON();
  }, [])

  useEffect(() => {
    disableBuyOnTotalSupply()
  }, [disableBuyOnTotalSupply])

  useEffect(() => {
    getBoughtNFTS()
  }, [getBoughtNFTS])

  return (
    <>
      <div id="main-wrapper">
        <Menu
          onClickCollection={() => {
            setRegenDialogState(!regenDialogState)
            setIsViewingCollection(true)
          }}
          canViewCollection={canViewCollection}
        />
        <div id="bg-wrapper"></div>
        <div id="content-top-wrapper">
          <div id="banner-wrapper">
            <div id="banner-main">
              <div className="banner-main-wrap">
                <div className="container">
                  <div className="banner-text">
                    <div className="banner-header">
                      <div className="banner-header-title">
                        <div className="banner-main-top">
                          <h1>Regenz Minter</h1>
                        </div>
                        <div className="banner-main-btm">
                          <p>
                            Nerd Finance proudly presents Regenz (Series 1), the
                            innovative, one-of-a-kind mashup of four today's
                            most iconic NFT collections: Wicked Craniums, Cool
                            Cats, Bored Ape Yacht Club, and Crypto Punks.
                          </p>
                        </div>
                        <div className="banner-main-input">
                          <div
                            className="banner-main-input-top"
                            id="banner-main-input-top"
                          >
                            <input
                              value={value}
                              onChange={(e: any) => setValue(e.target.value)}
                              type="number"
                              pattern="[0-9]+"
                            />
                            <button
                              id="mint-btn"
                              style={{ minWidth: 100 }}
                              disabled={
                                greaterThan(value, maxTokensPerTransaction) ||
                                !isFloat(value) ||
                                equalTo(value, 0) ||
                                allTokensAreBought ||
                                !account
                              }
                              className={`button btn-main ${isLoading && 'loading'
                                }`}
                              onClick={() => buyNFT()}
                            >
                              <span>Mint Regenz</span>
                            </button>
                          </div>
                          <div
                            className="banner-main-input-btm"
                            id="banner-main-input-btm"
                          >
                            <button
                              className="button l-btn sm"
                              onClick={() => setValue(3)}
                            >
                              <span>3</span>
                            </button>
                            <button
                              className="button l-btn sm"
                              onClick={() => setValue(6)}
                            >
                              <span>6</span>
                            </button>
                            <button
                              className="button l-btn sm"
                              onClick={() => setValue(9)}
                            >
                              <span>9</span>
                            </button>
                            <button
                              className="button l-btn sm"
                              onClick={() => setValue(12)}
                            >
                              <span>12</span>
                            </button>
                            <button
                              className="button l-btn sm"
                              onClick={() => setValue(15)}
                            >
                              <span>15</span>
                            </button>
                          </div>
                          {/* <div className='progress-custom'>
                            <span>{totalSupply} / {numberWithCommas(maxTotalSupply)}</span>
                            <LinearProgress variant="determinate" value={calculateProcentage()} />
                          </div> */}
                          <div className="progress-detailed">
                            <p
                              onClick={() => setTextDialogState(true)}
                              className="progress-read-more"
                            >
                              Click here for detailed information about Regenz
                              (Series 1)
                            </p>
                            <p>
                              View your Regenz on{' '}
                              <a
                                href="https://opensea.io/account"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <strong>OpenSea</strong>
                              </a>
                              ,{' '}
                              <a
                                href="https://rarible.com/items?tab=owned"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <strong>Rarible</strong>
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="banner-main-image">
                <img src={bannerImage} alt="Banner image" />
              </div>
            </div>
          </div>
        </div>
        <RegenDialog
          isLoading={showcaseNFTSIsLoading}
          isDebug={NFTShowcaseDebug}
          canViewCollection={canViewCollection}
          nfts={
            showcaseNFTS.length > 0 && isMinting ? showcaseNFTS : boughtNFTS
          }
          onClose={() => setRegenDialogState(false)}
          open={regenDialogState}
          isViewingCollection={isViewingCollection}
          onRegenerate={(nft: any) => {
            // set 
            setRegenNFT(nft);
            // close the collection modal
            setRegenDialogState(false);
            // open the regenerate modal
            setTimeout(() => {
              setRegenRegenerateState(true);
            }, 200);
          }}
        />

        <RegenRegenerate
          isLoading={false}
          isDebug={NFTShowcaseDebug}
          canViewCollection={canViewCollection}
          nfts={
            showcaseNFTS.length > 0 && isMinting ? showcaseNFTS : boughtNFTS
          }
          nft={regenNFT}
          onClose={() => {
            getBoughtNFTS();
            setRegenRegenerateState(false);
          }}
          open={regenRegenerateState}
          isViewingCollection={isViewingCollection}
        />

        <LoaderDialog
          open={loaderDialogState}
          title={loadingText[0]}
          subtitle={loadingText[1]}
          isSuccess={false}
          isError={true}
        />

        <TextDialog
          open={textDialogState}
          onClose={() => setTextDialogState(false)}
          price={BN(pricePerToken).toNumber()}
          maxBuy={BN(maxTokensPerTransaction).toNumber()}
          maxTotalSupply={maxTotalSupply}
        />
      </div>
    </>
  )
}

export default Home
